
@font-face {
  font-family: KOJ;
  src: url(../src/fonts/KnockoutHTF30JuniorWelterwt.otf);
}

@font-face {
  font-family: KOW;
  src: url(../src/fonts/KnockoutHTF50Welterweight.otf);
}

@font-face {
  font-family: Leviathan;
  src: url(../src/fonts/Leviathan-HTF-Black-Italic.otf);
}

body,
html,
#root {
  margin: 0;
  flex-grow: 1;
  width: 100%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(transparent, #3fa9f5);
}